<script lang="ts" setup>
	import type { CSSProperties } from "vue";
	import { ref, computed, watch, nextTick } from "vue";
	import { useResizeObserver } from "@vueuse/core";

	const props = withDefaults(
		defineProps<{
			id?: string;
			name?: string;
			value: string;
			maxlength?: number;
			autofocus?: boolean;
			minrows?: number;
			maxrows?: number;
			important?: boolean | string[];
			disabled?: boolean;
			readonly?: boolean;
		}>(),
		{
			minrows: 1,
			important: false,
		}
	);

	const value = ref<string>(props.value);
	const height = ref("auto");
	const element = ref<HTMLTextAreaElement | null>(null);

	const emit = defineEmits<{
		(e: "update:value", value: string): void;
		(e: "change", value: string): void;
		(e: "focusin"): void;
		(e: "focusout"): void;
	}>();

	const computedStyles = computed(() => {
		return {
			resize: !isResizeImportant.value ? "none" : "none !important",
			height: height.value,
			overflow: maxHeight.value ? "auto" : !isOverflowImportant.value ? "hidden" : "hidden !important",
			maxHeight: maxHeight.value ? maxHeight.value : null,
		} as CSSProperties;
	});

	const isResizeImportant = computed(() => {
		const imp = props.important;
		return imp === true || (Array.isArray(imp) && imp.includes("resize"));
	});

	const isOverflowImportant = computed(() => {
		const imp = props.important;
		return imp === true || (Array.isArray(imp) && imp.includes("overflow"));
	});

	const isHeightImportant = computed(() => {
		const imp = props.important;
		return imp === true || (Array.isArray(imp) && imp.includes("height"));
	});

	const resize = () => {
		const important = isHeightImportant.value ? "important" : "";
		height.value = `auto${important ? " !important" : ""}`;

		nextTick(() => {
			if (!element.value) return;
			const heightVal = element.value.scrollHeight + 1 + "px";
			height.value = `${heightVal}${important ? " !important" : ""}`;
		});
	};

	const maxHeight = computed(() => {
		if (props.maxrows && element.value) {
			const computedStyle = getComputedStyle(element.value);
			const lineHeight = parseFloat(computedStyle.lineHeight);

			return props.maxrows * lineHeight + "px";
		} else {
			return null;
		}
	});

	useResizeObserver(element, resize);

	watch(
		() => props.value,
		(val) => {
			val = val;
		}
	);

	watch(value, (val) => {
		nextTick(resize);
		emit("update:value", val);
	});

	watch(() => props.minrows, resize);

	watch(() => props.maxrows, resize);

	onMounted(function () {
		resize();
	});
</script>

<template>
	<textarea
		:id="props.id"
		:name="name"
		:autofocus="autofocus"
		:maxlength="maxlength"
		ref="element"
		:rows="minrows"
		:style="computedStyles"
		v-model="value"
		@focus="resize"
		@change="() => $emit('change', value)"
		@focusin="() => $emit('focusin')"
		@focusout="() => $emit('focusout')"
		:disabled="props.disabled"
		:readonly="props.readonly"
	></textarea>
</template>
