<script lang="ts" setup>
	import type { AutocompleteOptions, InputForm, InputTypes } from "~/utils/types";
	import {
		type Validator,
		emailValidator,
		slugValidator,
		textValidator,
		passwordValidator,
		ibanNumberValidator,
		numberValidator,
		swiftNumberValidator,
		zipValidator,
		abaNumberValidator,
		urlValidator,
		accountNumberValidator,
		ssnValidator,
	} from "~/utils/validators";

	const props = withDefaults(
		defineProps<{
			value?: string;
			placeholder: string;
			autocomplete?: AutocompleteOptions;
			autofocus?: boolean;
			maxlength?: number;
			required?: boolean;
			multiline?: boolean;
			validator?: Validator;
			type?: InputTypes;
			disabled?: boolean;
			//mask?: IMask.AnyMaskedOptions;
			form: InputForm;
			validationMessage?: string;
			readonly?: boolean;
			autoValidate?: boolean;
		}>(),
		{
			value: "",
			type: "text",
			autocomplete: "off",
			autofocus: false,
			required: true,
			multiline: false,
			disabled: false,
			readonly: false,
			autoValidate: true,
		}
	);

	const validationMessage = computed(() => {
		if (props.validationMessage) return props.validationMessage;
		if (props.type === "email") return "Please enter a valid email address.";
		if (props.type === "url") return "Please enter a valid URL.";
		if (props.type === "account-number") return "Please enter a valid account number.";
		if (props.type === "iban-number") return "Please enter a valid IBAN number.";
		if (props.type === "swift-number") return "Please enter a valid SWIFT number.";
		if (props.type === "aba-number") return "Please enter a valid ABA number.";
		if (props.type === "password") return "Password must have at least 8 characters.";
		if (props.type === "number") return "Please enter a valid number.";
		if (props.type === "zip") return "Please enter a valid ZIP code.";
		if (props.type === "ssn") return "Please enter a valid social security number.";
		return "Please enter a valid " + props.placeholder.toLowerCase() + ".";
	});

	const emit = defineEmits<{
		(e: "update:value", value: string): void;
		(e: "change"): void;
		(e: "focusin"): void;
		(e: "focusout"): void;
		(e: "enterpressed"): void;
	}>();

	const name = computed(() => {
		return props.placeholder.toLowerCase().replace(/ /g, "-");
	});

	const id = Math.random().toString(36);

	const shouldSlotShow = useShouldSlotShow();

	const hasFocus = ref<boolean>(false);

	const value = computed({
		get() {
			return props.value;
		},
		set(value: string) {
			emit("update:value", value);
		},
	});

	function focusIn() {
		hasFocus.value = true;
		isValidating.value = false;
		emit("focusin");
	}

	function focusOut() {
		hasFocus.value = false;
		if (props.autoValidate === true) {
			isValidating.value = true;
		}

		emit("focusout");
	}

	function enterPressed() {
		emit("enterpressed");
	}

	//

	const hasContent = computed(() => {
		return value.value.trim().length > 0;
	});

	//

	const isValid = computed(() => {
		if (!props.required) return true;

		const trimmed = value.value.trim();

		if (props.validator && typeof props.validator === "function") {
			return props.validator(trimmed);
		}
		if (props.type === "email") {
			return emailValidator(trimmed);
		} else if (props.type === "text") {
			return textValidator(trimmed);
		} else if (props.type === "password") {
			return passwordValidator(trimmed);
		} else if (props.type === "iban-number") {
			return ibanNumberValidator(trimmed);
		} else if (props.type === "number") {
			return numberValidator(trimmed);
		} else if (props.type === "swift-number") {
			return swiftNumberValidator(trimmed);
		} else if (props.type === "zip") {
			return zipValidator(trimmed);
		} else if (props.type === "url") {
			return urlValidator(trimmed);
		} else if (props.type === "slug") {
			return slugValidator(trimmed);
		} else if (props.type === "aba-number") {
			return abaNumberValidator(trimmed);
		} else if (props.type === "account-number") {
			return accountNumberValidator(trimmed);
		} else if (props.type === "ssn") {
			return ssnValidator(trimmed);
		} else if (props.type === "us-phone") {
			return usPhoneValidator(trimmed);
		}

		return true;
	});

	const isInvalid = computed(() => {
		return !isValid.value && isValidating.value;
	});

	// validation management ------------------------------------

	if (props.form[name.value]) {
		if (!process.dev) {
			//console.error(`Input with name "${name.value}" already exists in the form.`);
		}
	} else {
		props.form[name.value] = {
			validating: false,
			valid: isValid.value,
		};
	}

	watch(
		() => isValid.value,
		() => {
			props.form[name.value].valid = isValid.value;
		},
		{ immediate: true }
	);

	onBeforeUnmount(function () {
		delete props.form[name.value];
	});

	const isValidating = computed({
		get() {
			return props.form[name.value]?.validating ?? false;
		},
		set(value: boolean) {
			props.form[name.value].validating = value;
		},
	});
</script>

<template>
	<div class="input-text-field" :class="[{ 'has-focus': hasFocus }, { 'has-content': hasContent }, { disabled: disabled }, { validating: isValidating }, { valid: isValid }, { invalid: isInvalid }]">
		<label :for="id">
			<span>{{ placeholder }}</span>
			<span v-if="required" class="star">&nbsp;*</span>
		</label>

		<input-text
			v-if="!multiline"
			:id="id"
			:name="name"
			:maxlength="maxlength"
			:autocomplete="autocomplete"
			:type="type"
			v-model:value="value"
			@change="() => $emit('change')"
			@focusin="focusIn"
			@focusout="focusOut"
			@enterpressed="enterPressed"
			:disabled="disabled"
			:readonly="readonly"
		/>

		<input-textarea
			v-if="multiline"
			:id="id"
			:name="name"
			:maxlength="maxlength"
			:autocomplete="autocomplete"
			v-model:value="value"
			@change="() => $emit('change')"
			@focusin="focusIn"
			@focusout="focusOut"
			:disabled="disabled"
			:readonly="readonly"
		/>

		<p v-if="isValidating && isInvalid" class="tiny slot validation-message">
			{{ validationMessage }}
		</p>

		<div class="slot" v-if="shouldSlotShow && !(isValidating && isInvalid)">
			<slot :isValid="isValid" :isInvalid="isInvalid" :hasFocus="hasFocus" :isValidating="isValidating" :hasContent="hasContent"></slot>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.input-text-field {
		position: relative;
		display: flex;
		transition:
			all 0.1s ease-out,
			height 0s;
		width: 100%;
		@extend .gpu;
		pointer-events: all;
		flex-direction: column !important;
		text-align: left;
		font-size: 18px;

		.slot {
			margin-top: 6px;
			display: flex;
			width: 100%;
			flex-direction: column;
		}

		.validation-message {
			color: var(--red);
			@include SlideTop(0.2s, 2px);
		}

		textarea {
			resize: none;
			overflow: hidden;
		}

		input,
		textarea {
			background: var(--beige-light);
			border-radius: 8px;
			border: none;
			outline: none;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			background: var(--beige-light);
		}

		input,
		textarea {
			cursor: text !important;
			@include AstonMartinSans(false);
			@include FontWeightRegular(false);
		}

		input,
		label,
		textarea {
			width: 100%;
			box-sizing: border-box;
			transition: inherit;
			pointer-events: all;
		}

		label {
			position: absolute;
			pointer-events: none;
			font-weight: normal;
			color: var(--grey);

			span.star {
				color: var(--red);
				font-size: 14px;
				transform: translateY(-4px);
				display: inline-block;
			}
		}

		input,
		label,
		select,
		textarea {
			padding-left: 14px;
			padding-right: 14px;
		}

		input,
		textarea {
			padding-top: 22px;
			line-height: 24px;
			padding-bottom: 10px;
		}

		label {
			line-height: 56px;
		}

		&.has-content,
		&.has-focus {
			label {
				line-height: 34px;
				font-size: 10px;
				span.star {
					transform: translateY(-2px);
					font-size: 10px;
				}
			}
		}

		&.invalid {
			input,
			textarea {
				background: var(--red-light);
				color: var(--red);
				box-shadow: inset 0px 0px 0px 1px var(--red);
			}

			label {
				color: var(--red);
			}
		}

		/* &.valid {
		} */

		&.has-focus {
			input,
			select,
			textarea {
				background: none;
				color: var(--black);
				box-shadow: inset 0px 0px 0px 1px var(--black);
			}
		}

		&.readonly {
			input,
			textarea {
			}
		}
		&.disabled {
			pointer-events: none;
			input,
			select,
			textarea {
				background: none;
				color: var(--black);
				box-shadow: inset 0px 0px 0px 1px var(--beige-dark);
				pointer-events: none;
			}
		}
	}
</style>
